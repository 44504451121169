<template>
  <div>
    <v-card class="pa-4 mt-5 pa-lg-7" elevation="1" rounded="lg">
      <v-form ref="form" lazy-validation class="mt-4 mx-0 px-0">
        <v-row align="end">
          <v-col cols="12">
            <h6 class="subtitle-1 mb-5">Profile</h6>
          </v-col>

          <!-- First Name -->
          <v-col cols="12" sm="6" lg="4" class="mt-n5">
            <p class="label-text mb-1">
              First Name <span class="red--text">*</span>
            </p>

            <v-text-field
              class="py-0 my-0"
              v-model="auth.user.first_name"
              :disabled="editUserDetails ? disabled : ''"
              outlined
              dense
              required
            >
            </v-text-field>
          </v-col>

          <!-- Last Name -->
          <v-col cols="12" sm="6" lg="4" class="mt-n5">
            <p class="label-text mb-1">
              Last Name <span class="red--text">*</span>
            </p>
            <v-text-field
              v-model="auth.user.last_name"
              :disabled="editUserDetails ? disabled : ''"
              outlined
              dense
              required
            >
            </v-text-field>
          </v-col>

          <!-- First Phone Number -->
          <v-col cols="12" sm="6" lg="4" class="mt-n5">
            <p class="label-text mb-1">
              First Phone Number <span class="red--text">*</span>
            </p>

            <v-text-field
              v-model="auth.user.phone1"
              :disabled="editUserDetails ? disabled : ''"
              outlined
              dense
              required
            ></v-text-field>
          </v-col>

          <!-- Second Phone Number -->
          <v-col cols="12" sm="6" lg="4" class="mt-n5">
            <p class="label-text mb-1">
              Second Phone Number <span class="red--text">*</span>
            </p>

            <v-text-field
              v-model="auth.user.phone2"
              :rules="mobilePhoneRules"
              :disabled="editUserDetails ? disabled : ''"
              value="0"
              outlined
              dense
              required
            ></v-text-field>

            <p class="b-error mt-n6 mb-0 ml-3">
              <span v-if="cellularHasError">
                {{ cellularErrorMessage }}
              </span>
              <span v-else class="white--text">.</span>
            </p>
          </v-col>

          <!-- E-mail -->
          <v-col cols="12" sm="6" lg="4" class="mt-n5">
            <p class="label-text mb-1">
              E-mail <span class="red--text">*</span>
            </p>

            <v-text-field
              v-model="auth.user.email"
              :rules="emailRules"
              :disabled="editUserDetails ? disabled : ''"
              outlined
              dense
              required
            ></v-text-field>
          </v-col>

          <!-- btn -->
          <v-col cols="12" class="mt-n5">
            <!-- @click="editUserDetails = !editUserDetails" -->
            <!-- <v-btn
              v-if="editUserDetails == false"
              class="btn btn-secondary white--text"
              width="90"
              height="50"
            >
              Edit
            </v-btn>
            <v-btn
              v-if="editUserDetails == true"
              @click="validateForm()"
              class="btn btn-primary white--text"
              width="160"
              height="50"
            >
              Save changes
            </v-btn> -->
          </v-col>

          <!-- Overlay -->
          <v-overlay :value="overlay" z-index="10">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "DocumentManagementMain",

  data() {
    return {
      overlay: false,

      editUserDetails: false,

      disabled: null,
    };
  },

  computed: {
    ...mapState({ auth: "auth" }),
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
//
</style>
