<template>
  <div>
    <v-card class="pa-4 mt-5 pa-lg-7" elevation="1" rounded="lg">
      <v-form ref="form" lazy-validation class="mt-4 mx-0 px-0">
        <v-row align="end">
          <v-col cols="12">
            <h6 class="subtitle-1 mb-5">Change Password</h6>
          </v-col>

          <!-- Current Password -->
          <v-col cols="12" sm="6" lg="4" class="mt-n5">
            <p class="label-text mb-1">
              Current Password <span class="red--text">*</span>
            </p>

            <v-text-field
              v-model="currentPassword"
              :rules="currentPasswordRules"
              class="py-0 my-0"
              outlined
              dense
              required
            >
            </v-text-field>

            <p class="b-error mt-n5 mb-0 ml-3">
              <span v-if="currentPasswordHasError">
                {{ currentPasswordErrorMessage }}
              </span>
              <span v-else class="white--text">.</span>
            </p>
          </v-col>

          <!-- New Password -->
          <v-col cols="12" sm="6" lg="4" class="mt-n5">
            <p class="label-text mb-1">
              New Password <span class="red--text">*</span>
            </p>
            <v-text-field
              v-model="newPassword"
              :rules="newPasswordRules"
              outlined
              dense
              required
            >
            </v-text-field>

            <p class="b-error mt-n5 mb-0 ml-3">
              <span v-if="newPasswordHasError">
                {{ newPasswordErrorMessage }}
              </span>
              <span v-else class="white--text">.</span>
            </p>
          </v-col>

          <!-- Confirm Password -->
          <v-col cols="12" sm="6" lg="4" class="mt-n5">
            <p class="label-text mb-1">
              Confirm Password <span class="red--text">*</span>
            </p>

            <v-text-field
              v-model="confirmPassword"
              :rules="confirmPasswordRules"
              outlined
              dense
              required
            ></v-text-field>

            <p class="b-error mt-n5 mb-0 ml-3">
              <span v-if="confirmPasswordHasError">
                {{ confirmPasswordErrorMessage }}
              </span>
              <span v-else class="white--text">.</span>
            </p>
          </v-col>

          <!-- btn -->
          <v-col cols="12" class="mt-n5">
            <v-btn
              @click="validateForm()"
              class="btn btn-primary white--text"
              width="160"
              height="50"
            >
              Save
            </v-btn>
          </v-col>

          <!-- Overlay -->
          <v-overlay :value="overlay" z-index="10">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "DocumentManagementChangepassword",

  props: {
    redirect: {
      default: "",
      type: String,
    },
  },

  data() {
    return {
      overlay: false,
      editEmploymentDetails: false,

      // current password
      currentPassword: "",
      currentPasswordRules: [
        (v) => !!v || "Required",
        (v) => v.length >= 8 || "Can not be less than 8 characters",
        (v) => v.length <= 30 || "Can not be more than 30 characters",
        // (v) =>
        //   /^[a-zA-Z]+$/.test(v) ||
        //   "Password must contain only A to Z or a to z",
      ],
      currentPasswordHasError: false,
      currentPasswordErrorMessage: "password",

      // new password
      newPassword: "",
      newPasswordRules: [
        (v) => !!v || "Required",
        (v) => v.length >= 8 || "Can not be less than 8 characters",
        (v) => v.length <= 30 || "Can not be more than 30 characters",
        // (v) =>
        //   /^[a-zA-Z]+$/.test(v) ||
        //   "Password must contain only A to Z or a to z",
      ],
      newPasswordHasError: false,
      newPasswordErrorMessage: "new password",

      // Confirm Password
      confirmPassword: "",
      confirmPasswordRules: [
        (v) => !!v || "Required",
        (v) =>
          v === this.newPassword || "The password confirmation does not match.",
      ],
      confirmPasswordHasError: false,
      confirmPasswordErrorMessage: "confirm password",
    };
  },

  computed: {
    ...mapState({ auth: "auth" }),
  },

  mounted() {},

  watch: {
    // currentPassword
    currentPassword() {
      if (this.currentPasswordHasError) {
        this.currentPasswordHasError = false;
        this.currentPasswordErrorMessage = "";
      }
    },

    // newPassword
    newPassword() {
      if (this.newPasswordHasError) {
        this.newPasswordHasError = false;
        this.newPasswordErrorMessage = "";
      }
    },

    // confirmPassword
    confirmPassword() {
      if (this.confirmPasswordHasError) {
        this.confirmPasswordHasError = false;
        this.confirmPasswordErrorMessage = "";
      }
    },
  },

  methods: {
    ...mapMutations({
      resetAuthState: "auth/resetState",
    }),

    // validateForm
    validateForm() {
      this.$refs.form.validate();

      if (this.$refs.form.validate()) {
        this.changePasssword();
      }
    },

    // Change Passsword
    async changePasssword() {
      this.overlay = true;

      try {
        // let temp = {
        //   current_password: this.currentPassword,
        //   new_password: this.newPassword,
        //   confirm_password: this.confirmPassword,
        // };

        // console.log(temp);

        const res = await this.axios.post("change-password", {
          current_password: this.currentPassword,
          new_password: this.newPassword,
          confirm_password: this.confirmPassword,
        });

        // console.log(res);

        if (res.status == 200) {
          this.$swal({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "Your Password Has Been Changed",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });

          //   this.getUserDetail();

          // console.log(res.data);
        }
      } catch (error) {
        let res = error.response;
        let data = res.data;

        if (error.response.status == 401) {
          this.resetAuthState();

          if (this.redirect !== "") {
            this.$router.push("/login?redirect=" + this.redirect);
          } else {
            this.$router.push("/login");
          }
        } else if (error.response.status == 400) {
          // console.log(res);

          const errors = data.errors;

          if ("message" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.message,
            });
          }

          if ("current_password" in errors) {
            this.currentPasswordHasError = true;
            this.currentPasswordErrorMessage = errors.current_password[0];
          }

          if ("new_password" in errors) {
            this.newPasswordHasError = true;
            this.newPasswordErrorMessage = errors.new_password[0];
          }

          if ("confirm_password" in errors) {
            this.confirmPasswordHasError = true;
            this.confirmPasswordErrorMessage = errors.confirm_password[0];
          }

          // console.log(errors);
        } else if (error.response.status == 500) {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
      }

      this.overlay = false;
    },
  },
};
</script>

<style lang="scss" scoped>
//
</style>
