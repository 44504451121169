<template>
  <div class="profile">
    <!-- Main -->
    <Main />

    <!-- ChangePassword -->
    <ChangePassword />
  </div>
</template>

<script>
import { mapState } from "vuex";

import ChangePassword from "@/components/elements/dashboard/profile/ChangePassword";
import Main from "@/components/elements/dashboard/profile/Main";

export default {
  name: "DocumentManagementProfile",

  components: {
    ChangePassword,
    Main,
  },

  data() {
    return {
      overlay: false,
    };
  },

  computed: {
    ...mapState({ auth: "auth" }),
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
//
</style>
